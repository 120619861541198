import React from "react";

const PaymentReminder = () => {
  return (
    <div style={{ maxWidth: "1024px", margin: "auto" }}>
      <div style={{ fontSize: "24px" }}>
        <h1>Payment Reminder</h1>
        <p>Dear Aman,</p>
        <p>
          I hope this message finds you well. As per our agreement, there is an
          outstanding payment of ₹1,70,000 that is yet to be cleared. Despite my
          repeated reminders, I have not received any updates from your end
          regarding the payment.
        </p>
        <p>
          I want to inform you that if the pending amount is not cleared by
          December 1st, 2024, I will have no choice but to take action,
          including restricting access to the project's code repository and
          data. Please treat this as a final notice and respond promptly to
          resolve the matter amicably.
        </p>
        <p>Looking forward to your cooperation.</p>
        <p>Best regards,</p>
        <p>Shiv Choudhary</p>
      </div>
    </div>
  );
};

export default PaymentReminder;
