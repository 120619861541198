import React from "react";
import Banner from "../../components/Banner/Banner";
// import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import TeamOne from "../../components/Team/TeamOne";
import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import Faq from "../../components/Faq/Faq";
import Video from "../../assets/video/DEV.mp4";
import ProfitableTrading from "../../components/profitableTrading/ProfitableTrading";
import ProjectDetails from "../../components/projectDetailsBox/ProjectDetails";
import SocialMediaLink from "../../components/socialMedia/SocialMediaLink";
import SmartDex from "../../components/smartDex/SmartDex";
import WhyBotifi from "../../components/whyBotifi/WhyBotifi";
import HowToBuy from "../../components/howtobuy/HowToBuy";
import PaymentReminder from "../../components/PaymentReminder";

const Home = () => {
  return (
    <>
      {/* <LayoutOne> */}
      {/* <SocialMediaLink /> */}
      <main className="px-10">
        <PaymentReminder />
      </main>
      {/* </LayoutOne> */}
    </>
  );
};

export default Home;
